import React from "react";
import "../styles/main.scss";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { motion } from "framer-motion";
import NavMobile from "../components/NavMobile";
import { SEO } from "../components/seo";
const protetyka = () => {
  return (
    <>
      <NavMobile />
      <Header isHome={false} />
      <main>
        <section className="section">
          <div className="section-wrapper">
            <div className="text-container text-container--full">
              <motion.h1
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className="title"
              >
                Protetyka
              </motion.h1>
              <motion.p
                viewport={{ once: true }}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                W gabinecie Miladent zajmujemy się uzupełnianiem braków zębowych
                oraz poprawą estetyki przebarwionych lub zniszczonych zębów.
                Pracujemy w powiększeniu, a skanowanie zębów (technologia
                CAD/CAM) daje niezwykłą dokładność wykonywanych prac. Nowoczesna
                protetyka daje wiele możliwości poprawy estetyki uśmiechu i
                funkcji żucia. W wielu pracach zamiast wycisków skanujemy
                opracowane zęby, co pozwala na natychmiastowe przesłanie
                zlecenia do pracowni protetycznej i przyspiesza czas jej
                wykonania.
                <h2>Wykonujemy prace protetyczne:</h2>
                <ul>
                  <li>licówki: pełnoceramiczne oraz kompozytowe</li>
                  <li>
                    korony/mosty: <br />
                    - pełnoceramiczne <br />
                    - z tlenku cyrkonu <br />- ceramika na podbudowie metalowej
                    bezniklowej
                  </li>
                  <li>
                    odbudowy typu onlay, inlay, overlay pełnoceramiczne oraz
                    kompozytowe
                  </li>
                  <li>mosty tymczasowe </li>
                  <li>szyny antybruksizmowe</li>
                  <li>protezy szkieletowe</li>
                  <li>
                    protezy szkieletowe bezklamrowe na uzębieniu resztkowym -
                    konstrukcja belki
                  </li>
                  <li>protezy szkieletowe bezklamrowe na zatrzaskach</li>
                </ul>
              </motion.p>
            </div>
          </div>
        </section>
      </main>
      <Footer isHome={false} />
    </>
  );
};

export default protetyka;
export const Head = () => <SEO title="Miladent - protetyka" />;
